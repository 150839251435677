import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  // const [currentSlide, setCurrentSlide] = useState(0);

  const services = [
    {
      title: "AayushVaani",
      description:
        "Transcribe, Summarize and Prescribe",
      img: "./img/AayushVaani.png",
      explore: "https://aayushvaani.clinicalaiassistance.com/",
      access: "https://aayushvaani.clinicalaiassistance.com/",
    },
    {
      title: "ManoWealth",
      description:
        "Proactive mental health monitoring for university students with personalized screening tools and support.",
      img: "./img/manowealth.jpg",
      explore: "https://manowealth.clinicalaiassistance.com/",
      access: "https://manowealth.clinicalaiassistance.com/",
    },
    {
      title: "US",
      description:
        "Community platform for autistic children and caregivers, offering support and therapy.",
      img: "./img/Us2.png",
      explore: "/us",
      access: "/us",
    },
    {
      title: "Online Consultation",
      description:
        "Skip the wait & see a doctor from anywhere. Get expert care online with our virtual consultations.",
      img: "./img/online Dr. consultant.webp",
      explore: "/onlineDr",
      access: "/appointmentform",
    },
    {
      title: "Clinical Datasets",
      description:
        "Clinical data guides your virtual consult. Trusted insights for informed care.",
      img: "./img/MedicalDataset.jpg",
      explore: "/dataset",
      access: "/DatasetBookingForm",
    },
    {
      title: "Clinical Frameworks",
      description:
        "Streamline healthcare with AI-powered Medical Entity Recognition, Document Summarization, and Medical QA services.",
      img: "./img/ClinicalFrameworks.png",
      explore: "/framework",
      access: "/FrameworkBookingForm",
    },
    {
      title: "Mentorship",
      description:
        "Connect with expert mentors for personalized guidance in research and technical projects. Enhance your skills.",
      img: "./img/mentorship.jpg",
      explore: "/mentorship",
      access: "/mentorshipform",
    },
  ];

  return (
    <div className="container mx-auto mb-4">
      <h2
        style={{ fontFamily: "Roboto Slab" }}
        className="text-4xl text-teal-600 font-bold mt-14 mb-12 text-center"
      >
        Services We Provide
      </h2>{" "}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-28">
        {services.map((service, index) => (
          <div key={index} className="px-2 xl:px-6">
            <div
              className="rounded-lg h-[26rem] bg-teal-100 hover:bg-teal-300"
              // className={`rounded-lg h-[60vh] p-6 hover:bg-teal-400 ${
              //   index === (currentSlide + 1) % services.length
              //     ? "bg-teal-100"
              //     : "bg-teal-100"
              // }`}
            >
              <img
                src={service.img}
                alt={service.title}
                className="w-full h-60 rounded-t-lg border bg-white"
              />
              <h3 className="text-xl px-6 font-semibold mb-2 mt-2 text-gray-800">
                {service.title}
              </h3>
              <p className="mb-4 px-6 h-16 md:h-16 text-gray-800 text-[13px]">
                {service.description}
              </p>
              <div className="flex px-6 justify-between text-sm">
                <button
                  className="bg-white px-6 py-2 rounded-full w-full transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => {
                    service.title === "ManoWealth" || service.title === "AayushVaani"
                      ? window.open(service.explore, "_blank")
                      : navigate(service.explore);
                  }}
                >
                  Explore
                </button>

                {/* <button
                  className="bg-white px-6 py-2 rounded-full"
                  onClick={() =>
                    service.title === "ManoWealth" || service.title === "US"
                      ? window.open(service.access, "_blank")
                      : navigate(service.access)
                  }
                >
                  Access
                </button> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showPopup && (
        <VideoPopup show={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};

function VideoPopup({ show, onClose }) {
  const [iframeDimensions, setIframeDimensions] = useState({
    width: "560px",
    height: "315px",
  });

  useEffect(() => {
    function updateIframeDimensions() {
      if (window.innerWidth < 640) {
        setIframeDimensions({ width: "100%", height: "400px" });
      } else {
        setIframeDimensions({ width: "560px", height: "315px" });
      }
    }

    updateIframeDimensions();
    window.addEventListener("resize", updateIframeDimensions);
    return () => window.removeEventListener("resize", updateIframeDimensions);
  }, []);

  if (!show) return null;

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-fit">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <iframe
              width={iframeDimensions.width}
              height={iframeDimensions.height}
              src="https://www.youtube.com/embed/Wv7RKt1Vzpc?si=qkTFFyu2bnFR7tZ9"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="px-4 pb-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
