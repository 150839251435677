import React from "react";
import BarChart from "./BarChart";
import Dropdown from "./Dropdown";
const Vision = () => {
  const chartData = [
    {
      text: "How many years of experience do you possess in the field of medicine?",
      series: [24.8, 16.2, 16.2, 42.8],
      labels: ["1-3 years", "3-5 years", "5-10 years", "10+ years"],
    },
    {
      text: "When a patient visits you, how much time you invest in initial investigation/symptom investigation?",
      series: [4.8, 38.1, 39, 18.1],
      labels: ["1-3 minutes", "3-5 minutes", "5-7 minutes", "7-9 minutes"],
    },
    {
      text: "Do you think a junior doctor can save your time if they could have performed initial symptom investigation?",
      series: [73.8, 20, 6.2],
      labels: ["Yes", "Maybe", "No"],
    },
    {
      text: "How much time, the assistant can save for each patient (on an average)?",
      series: [1.9, 4.8, 3.8, 25.7, 45.7, 18.1],
      labels: [
        "0",
        "30 seconds",
        "1 minute",
        "1-2 minutes",
        "2-3 minutes",
        "3-4 minutes",
      ],
    },
  ];
  const publicationData = {
    Patent: [
      "System and Method for a Knowledge-Enhanced Multi-Modal Symptom Investigation and Disease Diagnosis Assistant, Jan 2024 (Granted)",
      "System and Method for Automated Disease Diagnosis, March 2023, (Filed & Published)",
    ],
    Conference: [
      "From Sights to Insights: Towards Summarization of Multimodal Clinical Documents - 62nd Annual Meeting of the Association for Computational Linguistics (ACL) 2024",
      "Seeing is believing! Towards Knowledge-Infused Multi-modal Medical Dialogue Generation, COLING 2024",
      "Experience and Evidence are the eyes of an excellent summarizer! Towards Knowledge Infused Multi-modal Clinical Conversation Summarization, 32nd ACM International Conference on Information and Knowledge Management (CIKM) 2023",
    ],
    Journal: [
      "Towards Knowledge-Infused Automated Disease Diagnosis Assistant, Scientific Reports - Nature, 2024",
      "Towards Symptom Assessment guided Symptom Investigation and Disease Diagnosis Assistant, IEEE Transactions on Artificial Intelligence, 2023",
    ],
  };

  return (
    <div className="w-full bg-white pt-[2vw]">
      <div className="md:px-16 lg:px-40 xl:px-72 mx-auto">
        <div>
          <h1 className="text-2xl underline py-[1vw] font-bold text-center px-2">
            Doctor Insights: Why they need an assistant? Can an AI-based assistant
            would be useful?{" "}
          </h1>

          <p className="px-2 mb-12 rounded-4 text-justify">
            In recent times, the healthcare system has been strained by long
            queues, patient dissatisfaction, and physician overload due to a
            shortage of healthcare providers. In our efforts to comprehend the
            issue, we engaged in discussions with 100 doctors affiliated with
            prestigious institutions like AIIMS. Our objective was to better
            comprehend the problem and explore innovative solutions. The
            summarized results are presented below.
          </p>
        </div>

        <div className="w-full flex flex-wrap justify-center">
          {chartData.slice(0, 3).map((data, index) => (
            <div className="bg-teal-100 p-4 mb-8 rounded-lg w-[95%] mx-auto lg:w-full">
              <h1 className="text-center md:text-xl lg:text-2xl mb-6 sm:px-8 sm:py-4">
                {data.text}
              </h1>
              <BarChart key={index} series={data.series} labels={data.labels} />
            </div>
          ))}
        </div>

        <div className=" px-2">
          <h1 className="text-2xl p-[1vw] mt-6 font-bold text-center underline">
            A new framework for Symptom Investigation and Diagnosis{" "}
          </h1>
          <img
            className="mb-16 rounded-lg p-4 border border-black bg-white"
            src=".\img\SQ_5.png"
            alt=""
          />
        </div>

        <div className="w-full flex flex-col lg:flex-row flex-wrap justify-center">
          {chartData.slice(3, 4).map((data, index) => (
            <div className="bg-teal-100 p-4 mb-8 rounded-lg w-[95%] mx-auto lg:w-full">
              <h1 className="md:text-xl lg:text-2xl text-center mb-6 sm:px-8 sm:py-4">
                {data.text}
              </h1>
              <BarChart key={index} series={data.series} labels={data.labels} />
            </div>
          ))}
          <p className="p-2 rounded-4 mb-10 text-justify ">
            Motivated by the scenarios, we build a conversational medical
            assistant that aids doctors in conducting thorough symptom
            investigations and making accurate diagnoses. The assistant serves
            as the initial point of contact for patients; it conducts an initial
            assessment and compiles a concise report for the physician. This
            efficient process saves doctors a significant amount of time that
            can be better utilized elsewhere.
          </p>
        </div>

        <h1 className="text-2xl p-[1vw] underline font-bold text-center">
          Research and Development{" "}
        </h1>
        <p className="p-2 rounded-4 text-justify ">
          The assistant we have developed is the culmination of two years of
          rigorous research and development. During this time, we have filed
          patents and published research in prestigious conferences and
          journals. Some of the details are provided below.
        </p>
        <div className="w-full mt-4 px-2">
          {Object.entries(publicationData).map(([title, content], index) => (
            <Dropdown key={index} title={title} content={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Vision;
