import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-teal-200 to-teal-600 px-8 py-8 md:px-10 lg:px-20 ">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 xl:ml-20 ">
        <div>
          
          <div className="mb-2">
            <h3 className="font-semibold text-lg">Contact Us</h3>
            <p className="text-sm">Clinical AI Assistance</p>
            <p className="text-sm">Model Town, Gurugram</p>
            <p className="text-sm">Haryana, 122001 India</p>
            <p className="text-sm">Email: info@clinicalaiassistance.com</p>
            <p className="text-sm">Mob: +91 8017153137</p>
          </div>
          <p className="mt-4 text-sm">
            All Rights Reserved@2023-2025 <br />
            <NavLink className="underline" to="/Tc">
              {" "}
              Terms & Policy
            </NavLink>
          </p>
          
        </div>
        <div className="">
        <h3 className="font-semibold text-lg mb-4 md:ml-14 mt-2 ">
            {" "}
            <a
              href="https://www.linkedin.com/company/clinical-ai-assistance/jobs/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              Careers
            </a>
          </h3>
          <div className="md:ml-14">
            <h3 className="font-semibold">FOLLOW US</h3>
            <div className="flex space-x-4 mt-2">
              <a rel="noreferrer"
                href="https://www.linkedin.com/company/clinical-ai-assistance"
                className="border p-2 rounded-full border-black hover:scale-105"
                target="_blank"
              >
                <FaLinkedin size={24} />
              </a>
              <a rel="noreferrer"
                href="https://twitter.com/_Clinical_AI_/"
                className="border p-2 rounded-full border-black hover:scale-105"
                target="_blank"
              >
                <FaTwitter size={24} />
              </a>

              <a rel="noreferrer"
                href="https://www.facebook.com/ClinicalAIAssistance"
                className="border p-2 rounded-full border-black hover:scale-105"
                target="_blank"
              >
                <FaFacebook size={24} />
              </a>
              <a rel="noreferrer"
                href="https://www.instagram.com/clinicalaiassistance/"
                className="border p-2 rounded-full border-black hover:scale-105"
                target="_blank"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>
        <div>
          <h3 className="font-semibold mb-2 mt-2 md:ml-14 text-lg">Supported By</h3>
          <div className="flex flex-col">
            <img
              className="h-16 w-40 rounded-lg mb-2 ml-0 md:ml-14"
              src=".\img\Microsoft.jpeg"
              alt=""
            />
            <img
              className="h-16 w-40 rounded-lg mb-2 ml-0 md:ml-14"
              src=".\img\fist2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
